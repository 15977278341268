import { template as template_021b4294096a41d398a0d09523aa548c } from "@ember/template-compiler";
const FKLabel = template_021b4294096a41d398a0d09523aa548c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
