import { template as template_43516925a53b43cb8080f37204e64c45 } from "@ember/template-compiler";
const FKText = template_43516925a53b43cb8080f37204e64c45(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
